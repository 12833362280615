.ant-table-thead > tr > th {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #1A1A1A !important;
}

.ant-table-row {
  &:hover {
    td > * {
      color: #000 !important;
    }
    background-color: rgba(246, 246, 255, 0.5) !important;
  }
}

.ant-table-clickable-row {
  cursor: pointer;
}

.ant-pagination-prev:focus .ant-pagination-item-link, 
.ant-pagination-next:focus .ant-pagination-item-link {
  color: #272727 !important;
  border-color: #d9d9d9 !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link, 
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #40A9FF !important;
  border-color: #40A9FF !important;
}

.custom-table .ant-table-tbody > tr > td {
  vertical-align: top;
}

.my-custom-table .ant-table-thead > tr > th {
  background: #ffffff !important;
}

.my-custom-table .ant-table {
  border: 1px solid #C7C7C7 !important;
  border-radius: 6px !important;
}

.no-hover-table .ant-spin-nested-loading > .ant-spin-container > div > .ant-table-content > .ant-table-body > table > tbody > tr:hover > td {
  background-color: unset !important;
}
.no-hover-table .ant-table-row {
  &:hover {
    background-color: unset !important;
  }
}
